import one from '../images/colors/DSC00568.jpg';
import two from '../images/colors/DSC00606.jpg';
import three from '../images/colors/DSC02952.jpg';
import four from '../images/colors/DSC03822.jpg';
import five from '../images/colors/DSC05598.jpg';
import six from '../images/colors/DSC05639.jpg';
import seven from '../images/colors/DSC06586.jpg';
import eight from '../images/colors/DSC07411.jpg';
import nine from '../images/colors/DSC08830.jpg';
import ten from '../images/colors/FH010019.jpg';
import eleven from '../images/colors/FH020013.jpg';
import twelve from '../images/colors/IMG_0053.jpg';
import thirteen from '../images/colors/IMG_0059.jpg';
import fourteen from '../images/colors/IMG_0162.jpg';
import fifteen from '../images/colors/gen0.jpg';
import sixteen from '../images/colors/gen1.jpg';
import seventeen from '../images/colors/gen2.jpg';
import eighteen from '../images/colors/gen3.jpg';

export const coloredPhotos = [
  {
    img: one,
    title: '1'
  },
  {
    img: two,
    title: '1'
  },
  {
    img: three,
    title: '1'
  },
  {
    img: four,
    title: '1'
  },
  {
    img: five,
    title: '1'
  },
  {
    img: six,
    title: '1'
  },
  {
    img: seven,
    title: '1'
  },
  {
    img: eight,
    title: '1'
  },
  {
    img: nine,
    title: '1'
  },
  {
    img: ten,
    title: '1'
  },
  {
    img: eleven,
    title: '1'
  },
  {
    img: twelve,
    title: '1'
  },
  {
    img: thirteen,
    title: '1'
  },
  {
    img: fourteen,
    title: '1'
  },
  {
    img: fifteen,
    title: '1'
  },
  {
    img: sixteen,
    title: '1'
  },
  {
    img: seventeen,
    title: '1'
  },
  {
    img: eighteen,
    title: '1'
  }
];
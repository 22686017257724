import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';

import Footer from "./components/footer";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import HomeBody from "./components/homeBody";
import TopBar from "./components/topBar";
import BlackAndWhiteGallery from "./components/blackAndWhiteGallery";
import ColorGallery from "./components/colorGallery";

function App() {

  const theme = createTheme({
    pallete: {
      primary: {
        main: '#ff4400'
      }
    }
  });

  return (<>
      <ThemeProvider theme={theme}>
        <Router>
          <TopBar/>
          <Switch>
            <Route path="/black-and-white" component={BlackAndWhiteGallery} />
            <Route path="/colors" component={ColorGallery} />
            <Route path="/" component={HomeBody} />
          </Switch>
        </Router>
        <Footer/>
      </ThemeProvider>
  </>
  );
}

export default App;

import * as React from 'react';
import { blackAndWhitePhotos } from './blackAndWhitePhotos';
import { coloredPhotos } from './coloredPhotos';

export default function Photos(props) {
  const itemData = props.type === 'blackAndWhite' ? blackAndWhitePhotos : coloredPhotos;

  const row = {
    padding: '10px', 
    display: 'flex', 
    flexWrap: 'wrap'
  }

  const column = {
    flex: '25%', 
    maxWidth: '40%', 
    padding: '15px 15px'
  }

  const img = {
    width: '100%'
  }

  const getGalleryPhotos = () => {
    return itemData.map((photos) => {
      return <>
        <div style={column}>
          <a href={`${photos.img}`} target="_blank" rel="noreferrer">
            <img alt="gallery" style={img} key={`${photos.img}`} src={`${photos.img}`}/>
          </a>
        </div> 
      </>;
    });
  }

  return (<>
      <div style={row}>
        {getGalleryPhotos()}
      </div>
    </>
  );
}

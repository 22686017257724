import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import homeImage from '../images/homephoto1i.gif';
import '../App.css';

export default function TopBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const style = {
    backgroundColor: 'black',
  }
  const instaStyle = {
    color: 'white',
    position: 'relative',
    top: 6
  }
  const blackText = {
    color: 'black'
  }
  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    marginRight: '12px'
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={style}>
        <Toolbar>
          <div id="mini-menu">
            <IconButton
              id="basic-button"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{ mr: 2 }}
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link style={blackText} to="/"> Home </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={blackText} to="/black-and-white"> Black and White </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={blackText} to="/colors"> Colors </Link>
              </MenuItem>
            </Menu>
          </div>
          <div className="home-image">
            <img className="profile-photo" src={homeImage} alt={"Film Affair Photography"}/>
          </div>    
          <div id="big-nav">
            <Link style={linkStyle} color="inherit" to="/">HOME</Link>
            <Link style={linkStyle} color="inherit" to="/black-and-white">BLACK AND WHITE</Link>
            <Link style={linkStyle} color="inherit" to="/colors">COLORS</Link>
            <a alt="follow on instagram" style={instaStyle} href="https://www.instagram.com/lafilmaffair/">
              <InstagramIcon />
            </a>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export default function Footer() {

  function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://filmaffairphotography.com/">
          Film Affair Photography
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', p: 5 }} component="footer">
      <Typography
        className="footer-text"
        variant="string"
        align="center"
        color="text.secondary"
        component="p"
      >
        Photography let's me open the window to the short stories I see when I capture an image.
      </Typography>
      <Copyright />
    </Box>
  )
}
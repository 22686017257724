import { Grid } from "@mui/material";
import blackAndWhites from '../images/blackandwhite/DSC00124.jpg';
import colors from '../images/colors/DSC00568.jpg';
import { Link } from "react-router-dom";
import Main from "./main";

export default function HomeBody () {
  const gridStyle = {
    textAlign:'center',
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid style={gridStyle} item xs={6}>
          <Link to={'/black-and-white'} style={{color: 'black'}}>
            <h1 id="black-white-h1">Black and White</h1>
            <div>
              <img width="90%" src={blackAndWhites} alt="black and white gallery"></img>
            </div>
          </Link>
        </Grid>
        <Grid style={gridStyle} item xs={6}>
          <Link to={'/colors'} style={{color: 'black'}}>
            <h1 id="color-h1">
              <span style={{color: 'purple'}}>C</span>
              <span style={{color: 'lightblue'}}>o</span>
              <span style={{color: 'orange'}}>l</span>
              <span style={{color: 'green'}}>o</span>
              <span style={{color: 'pink'}}>r</span>
            </h1>
            <div>
              <img width="90%" src={colors} alt="color gallery"></img>
            </div>
          </Link>
        </Grid>
      </Grid>
      <Main />
    </>
  )
}
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const theme = createTheme({
  pallete: {
    primary: {
      main: '#ff4400'
    }
  }
});

export default function Main() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">

            <Typography className="home-text" variant="body" align="center" color="text.secondary" paragraph>
              Photography is not only my passion,  it's my language of choice. It gives me the means to communicate so much that I could never express with words. 
              Film, I truly love!  The textures, hues, and rendering of an image that is unique to film is beautiful to me.  
              It allows me to articulate the feelings I felt in a moment, convey a mood, or show what I see in a person. 
            </Typography>            
            <Typography
              component="h5"
              variant="h7"
              align="center"
              color="text.primary"
              gutterBottom
            >
              - Leslie King
            </Typography>
          </Container>
        </Box>
      </main>
    </ThemeProvider>
  );
}
import one from '../images/blackandwhite/DSC00124.jpg';
import three from '../images/blackandwhite/DSC01073.jpg';
import four from '../images/blackandwhite/DSC01086.jpg';
import five from '../images/blackandwhite/DSC01565.jpg';
import six from '../images/blackandwhite/DSC01998.jpg';
import seven from '../images/blackandwhite/DSC02085.jpg';
import eight from '../images/blackandwhite/DSC02190.jpg';
import nine from '../images/blackandwhite/DSC02574.jpg';
import ten from '../images/blackandwhite/DSC02601.jpg';
import eleven from '../images/blackandwhite/DSC02660.jpg';
import twelve from '../images/blackandwhite/DSC03660.jpg';
import thirteen from '../images/blackandwhite/DSC03672.jpg';
import fourteen from '../images/blackandwhite/DSC03673.jpg';
import fifteen from '../images/blackandwhite/DSC03735.jpg';
import sixteen from '../images/blackandwhite/DSC04087.jpg';
import seventeen from '../images/blackandwhite/DSC04636.jpg';
import eighteen from '../images/blackandwhite/DSC05274.jpg';
import nineteen from '../images/blackandwhite/DSC05340.jpg';
import twentyOne from '../images/blackandwhite/DSC05588.jpg';
import twentyTwo from '../images/blackandwhite/DSC05633.jpg';
import twentyThree from '../images/blackandwhite/DSC05637.jpg';
import twentyFour from '../images/blackandwhite/DSC05820.jpg';
import twentyFive from '../images/blackandwhite/DSC06415.jpg';
import twentySix from '../images/blackandwhite/DSC07736.jpg';
import twentySeven from '../images/blackandwhite/DSC08089.jpg';
import twentyEight from '../images/blackandwhite/DSC08398.jpg';
import twentyNine from '../images/blackandwhite/DSC08415.jpg';
import thirtyOne from '../images/blackandwhite/DSC08451.jpg';
import thirtyTwo from '../images/blackandwhite/DSC08595.jpg';
import thirtyThree from '../images/blackandwhite/DSC08790.jpg';
import thirtyFour from '../images/blackandwhite/DSC08887.jpg';
import thirtyFive from '../images/blackandwhite/DSC08972.jpg';
import thirtySix from '../images/blackandwhite/DSC09050.jpg';
import thirtySeven from '../images/blackandwhite/DSC09955.jpg';
import thirtyEight from '../images/blackandwhite/DSC09971.jpg';
import thirtyNine from '../images/blackandwhite/DSC05265.jpg';

export const blackAndWhitePhotos = [
  {img: one, title:'1'},
  {img: three, title:'1'},
  {img: four, title:'1'},
  {img: five, title:'1'},
  {img: six, title:'1'},
  {img: seven, title:'1'},
  {img: eight, title:'1'},
  {img: nine, title:'1'},
  {img: eleven, title:'1'},
  {img: twelve, title:'1'},
  {img: thirteen, title:'1'},
  {img: fourteen, title:'1'},
  {img: ten, title:'1'},
  {img: fifteen, title:'1'},
  {img: sixteen, title:'1'},
  {img: seventeen, title:'1'},
  {img: eighteen, title:'1'},
  {img: nineteen, title:'1'},
  {img: twentyOne, title:'1'},
  {img: twentyTwo, title:'1'},
  {img: twentyThree, title:'1'},
  {img: twentyFour, title:'1'},
  {img: twentyFive, title:'1'},
  {img: twentySix, title:'1'},
  {img: twentySeven, title:'1'},
  {img: twentyEight, title:'1'},
  {img: twentyNine, title:'1'},
  {img: thirtyOne, title:'1'},
  {img: thirtyTwo, title:'1'},
  {img: thirtyThree, title:'1'},
  {img: thirtyFour, title:'1'},
  {img: thirtyFive, title:'1'},
  {img: thirtySix, title:'1'},
  {img: thirtySeven, title:'1'},
  {img: thirtyEight, title:'1'},
  {img: thirtyNine, title: '1'}
];